<template>
    <div class="box">
		<SeasonInput v-model="seasonSelected" @change="loadSeasonMareStallions" />

        <CustomTable
            id_table="jumenterie"
            ref="croisement"
            :items="season_mare_stallions"
            :busy.sync="table_busy"
            primaryKey="seasonmarestallion_id"
            :hide_if_empty="true"
			:hrefsRoutes="config_table_hrefs"
            :externSlotColumns="['mare.horse.horse_tiers']"
         >
			<template v-slot:[`custom-slot-cell(mare.horse.horse_tiers)`]="{ data }">
				<router-link 
					v-for="tiers in data.mare.horse.horse_tiers" 
					:to="{ name: 'tiersFiche', params: { tiers_id: tiers.tiers_id } }"
					:key="data.seasonmarestallion_id+'_'+tiers.tiers_id">
					{{ tiers.tiers_rs }}<br>
				</router-link>
			</template>
    	</CustomTable>

		<ModalPrintInfosHorse ref="modal_print_infos_horse"/>
		<ModalAddCross ref="modal_add_cross" @submit="changeSeason"/>

		<b-modal ref="modal_change_dps" size="xl" hide-footer>
			<template v-slot:modal-title>
				{{ $t("gynecologie.change_dps") }}
			</template>
			
			<input type="text" class="form-control" :placeholder="$t('gynecologie.new_num_dps')"
				v-model="season_mare_stallion.seasonmarestallion_num_dps"
			>
			<div class="text-center">
				<button class="btn btn-primary mt-4" type="button" @click.prevent="saveNewDps">{{ $t('global.valider') }}</button>
			</div>
		</b-modal>

		<b-modal ref="modal_confim_change_dps" hide-footer>
			<template v-slot:modal-title>
				{{ $t("gynecologie.change_dps") }}
			</template>

			<h5 class="text-center">{{ $t('gynecologie.confirmation_change_dps') }}</h5>

			<div class="row justify-content-around mt-4" >
				<button class="btn btn-primary" type="button" @click.prevent="saveChange(false)">{{ $t('global.non') }}</button>
				<button class="btn btn-primary" type="button" @click.prevent="saveChange(true)">{{ $t('global.oui') }}</button>
			</div>
		</b-modal>
    </div>
</template>

<script type="text/javascript">
    import GynecologieMixin from "@/mixins/Gynecologie.js"
	import Navigation from '@/mixins/Navigation.js'

    export default {
        name: "Croisements",
        mixins: [GynecologieMixin, Navigation],
        props: ['horse'],
        data () {
            return {
                table_busy: true,
                seasonSelected: null,
                seasons: [],
				season_mare_stallions: [],
				season_mare_stallion: {},
				config_table_hrefs: {
                    'mare.horse.horse_nom': {
						routeUniqueName: 'JumentFiche',
                        routeName: 'HorseFicheInfo',
                        params: {
                            horse_id: 'mare.horse.horse_id'
                        }
					},
					'horse.horse_nom': {
						routeUniqueName: 'EtalonFiche',
                        routeName: 'HorseFicheInfo',
                        params: {
                            horse_id: 'horse.horse_id'
                        }
                    },
					'porteuse.horse.horse_nom': {
						routeUniqueName: 'PorteuseFiche',
						routeName: 'HorseFicheInfo',
						params: {
							horse_id: 'porteuse.horse.horse_id'
						}
					}
                },
				events_tab: {
					'TableAction::goToPrintInfosHorseJumenterie': (params) => {
						this.openModalPrintInfosHorse(params)
					},
					'TableAction::goToPdfSuivi': (params) => {
						this.load_pdf_suivi(params)
					},
					'TableAction::goToAddCross': () => {
						this.openModalAddCross()
					},
					'TableAction::goToChangeDpsNum': (params) => {
						this.changeDpsNum(params)
					},
					'TableAction::goToArchiveCouple': (params) => {
						this.goToArchiveCouple(params)
					}
				},
            }
        },
        methods: {
            async changeSeason() {
                this.table_busy = true
                this.season_mare_stallions = await this.croisement(this.seasonSelected.id)
                this.table_busy = false
            },
			load_pdf_suivi(params) {
				params.forEach(croisement => {
					if(!croisement.horse.horse_id) {
						this.failureToast("toast.no_current_stallion")
					}
					this.generatePdfSuiviGyneco(croisement.mare.horse.horse_id, this.seasonSelected.id, croisement.horse.horse_id)
				});
			},
			async changeDpsNum(params) {
				const teledeclaration = await this.getHorseDpsStatus()
				if(!teledeclaration) {
					this.season_mare_stallion = params[0]
					this.$refs.modal_change_dps.show()
				}
				else {
					this.failureToast("toast.teledeclaration")
				}
			},
			async saveNewDps() {
				this.$refs.modal_change_dps.hide()
				this.$refs.modal_confim_change_dps.show()
			},
			async loadSeasonMareStallions() {
				if(!this.seasonSelected) {
					return
				}

				this.table_busy = true

				this.season_mare_stallions = await this.croisement(this.seasonSelected.id)

				this.table_busy = false
            },
			openModalPrintInfosHorse(params) {
				let mare_ids = []
				params.forEach(croisement => {
					mare_ids.push(croisement.mare.horse.horse_id)
				});
				this.$refs.modal_print_infos_horse.openModal(mare_ids)
			},
			openModalAddCross() {
				if(this.seasonSelected.id) {
					this.$refs.modal_add_cross.openModal(this.seasonSelected.id)
				}
				else {
					this.failureToast("error.LEP")
				}
			},

            async goToArchiveCouple(sms) {
                await this.archiveOrUnarchiveCouple(sms)
                this.$refs.croisement.unselectAll()
                this.changeSeason()
            },

            async saveChange(update) {
				this.$refs.modal_confim_change_dps.hide()
				if (update){
					const res = await this.editSeasonMareStallion(this.season_mare_stallion.seasonmarestallion_id, this.season_mare_stallion)
					if (res){
						this.$refs.croisement.refreshTable()
						this.infoToast('toast.info_modif_succes')
						return
					}
					this.failureToast(this.getTrad("horse.info_modif_failed"), true)
				}
            },

			
        },
        components: {
            CustomTable : () => import('GroomyRoot/components/Table/CustomTable'),
            SeasonInput : () => import('@/components/Inputs/SeasonInput'),
			ModalPrintInfosHorse : () => import('@/components/Horse/ModalPrintInfosHorse'),
			ModalAddCross : () => import('@/components/Gynecologie/ModalAddCross')
        }
    }
</script>
